@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

$primary-color: #00a8ff;
$primary-blue: #3cbdff;
$grey-color: #c4c9cc;
$grey-font-color: #565d61;
$white: #fff;
$black: #000;
$green-color: #82ce34;
$promilo-blue: #006599;

body {
  margin: 0;
  font-family: "Roboto", sans-serif !important;
  overflow-x: hidden;
  color: #101314;
}

*:focus {
  box-shadow: none;
  outline: 0 !important;
}

.font-10 {
  font-size: 10px;
}

.error-massage {
  color: red;
  font-size: small;
}

.inbox-content-container {
  flex: 1;
  overflow-y: auto;
  max-height: calc(100vh - 60px);
}

.inbox-content-container::-webkit-scrollbar {
  display: none;
}

.inbox-main-content {
  display: flex;
  overflow-y: auto;
  max-height: calc(100vh - 60px);
}

.inbox-side-menu {
  width: 300px;
  overflow-y: auto;
  max-height: calc(100vh - 150px);
}

.card.active {
  background-color: rgba(178, 232, 255, 0.582);
  transition: background-color 0.3s ease;
}


.card.inactive {
  background-color: white;
  color: black;
}

.sidenav-menu {
  min-height: 100vh;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  width: 240px;
  position: sticky;
  top: 0;

  .home {
    padding: 12px 15px;
    border-bottom: 1px solid $grey-color;

    .logo {
      max-width: 8rem;
    }
  }

  nav {
    padding-top: 1rem;

    ul {
      list-style-type: none;
      padding: 0;
      font-size: 12px;

      li {
        width: 100%;
        display: block;
        text-decoration: none;
        padding: 0.8rem;
        cursor: pointer;

        &.active {
          background-color: rgba(0, 168, 255, 0.15);
          color: #006599;

          img {
            filter: unset;
          }
        }

        .active {
          background-color: rgba(0, 168, 255, 0.15);
          color: #006599;

          img {
            filter: unset;
          }
        }

        img {
          margin-right: 0.4rem;
          filter: grayscale(1);
          opacity: 0.7;
          width: 16px;
          height: auto;
        }

        &:hover {
          background-color: rgba(0, 168, 255, 0.15);
          transition: 0.2s ease-in;

          img {
            filter: unset;
          }
        }
      }
    }

    .feeds-dropdown {
      padding: 0;

      &::after {
        content: "";
        position: absolute;
        top: 12px;
        right: 12px;
        background-image: url(../images/chevron-down.png);
        background-size: 100%;
        width: 16px;
        height: 16px;
        transition: transform 0.2s ease-in-out;
      }

      li {
        padding-left: 2rem;
        color: #000;
        height: 40px;
      }

      .accordion-title {
        padding: 0.8rem;
        display: block;
      }

      &:hover {
        background-color: transparent;

        .accordion-title {
          background-color: $primary-color;
        }
      }
    }

    .feeds-dropdown-active {
      padding: 0;

      &::after {
        content: "";
        position: absolute;
        top: 12px;
        right: 12px;
        background-image: url(../images/chevron-down.png);
        background-size: 100%;
        width: 16px;
        height: 16px;
        transition: transform 0.2s ease-in-out;
      }

      li {
        padding-left: 2rem;
        color: #000;
        height: 40px;
      }

      .accordion-title {
        padding: 0.8rem;
        display: block;
      }

      &:hover {
        background-color: transparent;

        .accordion-title {
          background-color: $primary-color;
        }
      }
    }

    .feeds-dropdown-active {
      &::after {
        content: "";
        transform: rotate(-180deg);
      }
    }
  }
}

.main-section {
  width: calc(100% - 240px);
}

.top-nav-bar {
  .dropdown-toggle {
    background-color: transparent;
    padding: 0;
    border: 0;
    color: #1f384c;
    font-size: 12px;
    display: flex;
    align-items: center;
    width: 100%;

    .user-img {
      width: 32px;
      height: 32px;
      border-radius: 32px;
    }

    &::after {
      border: 0;
      background-image: url(../images/chevron-down.png);
      width: 12px;
      height: 12px;
      background-size: contain;
    }

    .username-text {
      width: calc(100% - 40px);
    }
  }

  .dropdown-menu {
    font-size: 12px;
    padding: 10px 15px;
    border: 0;
    box-shadow: 0px 3px 12px #d2d2d2;
    border-radius: 2px;
    top: 12px !important;
    width: 100%;

    div {
      padding-bottom: 4px;
      padding-top: 4px;
    }
  }

  .search-field {
    height: 42px;
    border-radius: 4px;
    border: 1px solid $grey-color;
    font-size: 12px;

    input {
      border: none;
      font-size: 12px;

      &:focus {
        box-shadow: none;
        border: none
      }
    }

    &:focus {
      box-shadow: none;
      border: 1px solid $primary-blue;
    }

    &::placeholder {
      color: $grey-color;
    }
  }

  .search-icon {
    position: absolute;
    right: 12px;
    top: 12px;
  }
}

.listing-table {
  border-collapse: separate;
  border-spacing: 0;

  table,
  th,
  td {
    border: 1px solid $grey-color;
    // border-color: #006599;
    border-width: 1px;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    align-items: center;
    padding: 0.7rem 1.5rem !important;
    color: $grey-font-color !important;

    img {
      width: 16px;
      height: auto;
      cursor: pointer;
    }
  }

  td {
    border-bottom: 0px;
    border-top: 0px;
  }

  th {
    background-color: $primary-color !important;
    color: $white !important;
    text-align: left !important;
    justify-content: baseline !important;
  }

  tr:first-child {
    th {
      &:first-child {
        border-top-left-radius: 10px;
      }

      &:last-child {
        border-top-right-radius: 10px;
      }
    }
  }

  tr:last-child {
    td {
      border-bottom: 1px solid $grey-color;

      &:first-child {
        border-bottom-left-radius: 10px;
      }

      &:last-child {
        border-bottom-right-radius: 10px;
      }
    }
  }

  tr {
    &:nth-child(odd) {
      td {
        background-color: rgba(0, 168, 255, 0.05);
      }
    }

    &:nth-child(even) {
      td {
        background-color: rgba(0, 168, 255, 0.05);
      }
    }

    &.selected {
      td {
        background-color: rgba(0, 168, 255, 0.15) !important;
      }
    }

    &:first-child {
      td {
        background-color: $primary-blue;
        color: $white !important;
      }
    }
  }

  tbody {
    tr {
      &:nth-child(even) {
        td {
          background-color: #fff;
        }
      }
    }
  }

  .checkbox {
    border: 1px solid $grey-font-color !important;
  }

  .form-check-input {
    background-color: #c4c9cc50;
    border: 1px solid $grey-font-color;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    margin-top: 0;
    cursor: pointer;

    &:checked {
      background-color: #fff;
      border-color: $grey-font-color;
      background-image: url(../images/icons/check.png) !important;
      background-size: 80%;
      border: 1px solid $grey-font-color;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .first-row {
    tr {
      &:first-child {
        td {
          background-color: rgba(0, 168, 255, 0.05) !important;
          color: $grey-font-color !important;
        }
      }

      &:nth-child(odd) {
        td {
          background-color: rgba(0, 168, 255, 0.05);
        }
      }
    }
  }
}

.feeds-first-row {
  tr {
    &:nth-child(1) {
      td {
        background-color: rgba(0, 168, 255, 0.05);
        color: #565d61 !important;
      }
    }
  }
}

.download-popup {
  .download-btn {
    font-size: 12px !important;
    font-weight: 500 !important;
    color: $white !important;
    background-color: $primary-color !important;
    border: 0;
    width: 135px;
    height: 32px;
    border-radius: 6px;

    .download-icon {
      width: 11px;
      height: 11px;
    }
  }
}

.download-report-btn {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: $white !important;
  background-color: $primary-color !important;
  border: 0;
  width: 135px;
  height: 32px;
  border-radius: 6px;

  .download-icon {
    width: 11px;
    height: 11px;
  }
}

.download-modal {
  top: 50px;
  max-width: 370px !important;

  .modal-content {
    border: 2px solid $green-color;

    .top-img-wrapper {
      top: -38px;

      .top-img {
        width: 75px;
        height: 75px;
      }
    }

    h1 {
      font-weight: 600;
    }

    h1,
    p {
      color: $grey-font-color;
    }

    p {
      max-width: 290px;
    }

    .ok-btn {
      max-width: 360px;
      height: 36px;
      background-color: $green-color;
      border-radius: 4px;
    }
  }
}

.font-11 {
  font-size: 11px;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.pointer {
  cursor: pointer;
}

.calendar-btn {
  height: 32px !important;
  min-width: 170px !important;
  border: 1px solid #565d61 !important;
  border-radius: 6px !important;
  color: #565d61 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  padding: 6px 40px 6px 12px !important;

  img {
    width: 30px;
    right: 5px;
    top: 8px;
  }
}

.rdrInputRanges {
  display: none;
}

.date-range-picker-calendar-wrapper {
  position: absolute;
  top: 38px;
  right: 0;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #e3e3e3;
  box-shadow: 0 8px 25px rgb(201 201 201 / 22%);
  z-index: 1;

  .date-range-picker-submit {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0, 4px, 4px, 4px;

    button {
      width: 120px;
      height: 32px;
      border-radius: 4px;
      background-color: $primary-blue;
      color: #fff;
      margin: 8px;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.change-password-form {
  max-width: 300px;
  margin: auto;

  .label-text {
    color: #565d61;
    font-weight: 400;
    font-size: 12px;
  }

  .password-input {
    max-width: 300px;
    height: 40px;
    border: 1px solid #565d61;
    border-radius: 4px;
  }

  .eye-icon {
    width: 18px;
    height: 18px;
    right: 10px;
    top: 10px;
  }

  .save-btn {
    max-width: 300px;
    height: 39px;
    border-radius: 4px;
    background-color: #006599;
    font-size: 16px;
    font-weight: 500;
  }
}

.login-banner {
  border-radius: 15px;
}

.pagination-section {
  .pagination {
    justify-content: end;
    margin-bottom: 0;

    a {
      margin-left: 0.4rem;
      padding: 0.1rem 0.3rem;
      font-size: 10px;
      text-decoration: none;
      color: #101314;
    }

    .active {
      a {
        background-color: transparent;
        border: 1px solid $primary-blue;
        border-radius: 4px;
        color: $primary-blue;
        width: 10px;
        height: 10px;
      }
    }

    li:first-child,
    :last-child {
      a {
        display: flex;
        align-items: end;
        justify-content: center;
        width: 15px;
        height: 20px;
      }
    }

    li:last-child {
      img {
        transform: rotate(180deg);
      }
    }
  }

  .total-pages {
    span {
      position: absolute;
      left: 8px;
      font-size: 10px;
      top: 2px;
    }

    .dropdown {
      button {
        height: 18px;
        background-color: transparent !important;
        color: $primary-blue !important;
        border-color: $primary-blue !important;
        align-items: center;
        padding: 0;
        width: 45px;
        border-radius: 2px;
        display: flex;
        justify-content: space-around;
      }

      .dropdown-toggle {
        justify-content: end;
        border-radius: 4px;

        &::after {
          margin-right: 0.4rem;
        }
      }
    }

    .dropdown-menu {
      font-size: 12px;

      .dropdown-item:hover {
        background-color: $primary-blue !important;
        color: #fff !important;
      }
    }
  }
}

input[type="password"] {
  &::-ms-reveal {
    display: none;
  }
}

.filter-table-buttons {
  .btn {
    background-color: #fff !important;
    color: $primary-blue;
    height: 40px;
    border-radius: 4px;
    padding: 0 2rem;
    border: 1px solid $primary-blue !important;
    font-size: 14px;
    font-weight: 500;
    font-family: "Roboto", sans-serif !important;

    &.active {
      background-color: $primary-blue !important;
      color: #fff !important;
    }

    &:hover {
      background-color: inherit;
      color: $primary-blue;
    }
  }
}

.text-grey {
  color: $grey-font-color;
}

.service-request-page {
  textarea {
    border-radius: 8px;
    min-height: 150px;
    border: 1px solid $grey-color;
    resize: none;
  }

  .business-email-input {
    border-radius: 8px;
    border: 1px solid $grey-color;
    max-width: 300px;
    height: 40px;
    padding-left: 14px;
  }

  .newTicket-upload {
    display: flex;
    align-items: center;

    .upload-label {
      border-radius: 8px;
      max-width: 150px;
      height: 40px;
      background-color: $promilo-blue;
      margin-right: 10px;
    }

    .newTicket-upload-text {
      display: grid;
      place-items: flex-start;
      font-weight: 400;

      .text-grey-light {
        color: #8D9599;
      }

      .text-grey {
        color: #565D61;
      }
    }
  }

}

.default-button {
  border-radius: 8px;
  height: 40px;
  max-width: 150px;

  &.cancel-btn {
    border: 1px solid $promilo-blue;
    background-color: $white;
    color: $promilo-blue;
  }

  &.submit-btn {
    background-color: $promilo-blue;
    border: 0;
    color: $white;
  }
}

.pending-ticket-modal {
  .modal-header {
    background-color: rgba($primary-color, 0.15);
    padding: 8px 12px;
    border-bottom: 0;

    .modal-title {
      font-size: 16px;
      font-weight: 500;
      color: $black;
    }

    .btn-close {
      background-image: url(../images/icons/modal-close.png);
      opacity: 1;
      background-size: 18px;
    }
  }

  .modal-body {
    padding: 12px;

    .label-text {
      color: $grey-font-color;
    }

    .input-text,
    textarea {
      border: 1px solid $grey-font-color;
      border-radius: 4px;
    }

    .input-text {
      height: 40px;
    }

    textarea {
      min-height: 300px;
      resize: none;
    }
  }
}

.file-attach-icon {
  &.disable {
    pointer-events: none;
    opacity: 0.6;
  }
}

.inbox-side-menu {
  background-color: #f1f1f1;
  min-height: 80vh;
  width: 350px;
  margin-right: 5px;

  .card {
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #d9d9d9;
    max-height: 80px;
    overflow: hidden;

    &.read {
      background-color: #e5f6ff;
    }

    .show-user-name {
      width: 32px;
      height: 32px;
      min-width: 32px;
      min-height: 32px;
      border-radius: 32px;
    }

    .card-body {
      .card-text {
        color: $promilo-blue;
      }

      .card-sub-text {
        color: $grey-font-color;

        p {
          margin-bottom: 0.5rem;
        }
      }

      .show-date {
        color: $grey-font-color;
      }
    }
  }

  .card:hover {
    border-left: 4px solid rgba(48, 82, 250, 0.817);
    transition: border-left 0.2s ease-in-out;
  }

  figure {
    img {
      width: 100%;
    }
  }
}

.inbox-main-content {
  background-color: #f1f1f1;
  min-height: 80vh;
  flex: 1;

  .card {
    border-radius: 0;
    border: 0;

    .show-user-name {
      width: 42px;
      height: 42px;
      min-width: 42px;
      min-height: 42px;
      border-radius: 32px;
    }

    .content-wrapper,
    .time-stamp {
      color: $grey-font-color;

      p {
        margin-bottom: 0.5rem;
      }
    }
  }

  figure {
    img {
      width: 100%;
    }
  }
}

.switch-input {
  input[type="checkbox"] {
    display: none;

    +label {
      width: 36px;
      height: 18px;
      border-radius: 9px;

      &::before {
        content: "";
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 14px;
        left: 2px;
        top: 1px;
        transition: all 0.3s ease;
        position: absolute;
      }
    }

    &:checked {
      +label {
        &::before {
          background-color: $white !important;
          left: auto;
          top: 1px;
          right: 2px;
          transition: all 0.3s ease;
        }
      }
    }
  }

  &.switch-blue {
    input[type="checkbox"] {
      +label {
        border: 1px solid $primary-blue;

        &::before {
          background-color: $primary-blue;
        }
      }

      &:checked {
        +label {
          background-color: $primary-blue;
        }
      }
    }
  }

  &.switch-green {
    input[type="checkbox"] {
      +label {
        border: 1px solid $green-color;

        &::before {
          background-color: $green-color;
        }
      }

      &:checked {
        +label {
          background-color: $green-color;
        }
      }
    }
  }

  &.switch-red {
    input[type="checkbox"] {
      +label {
        border: 1px solid red;

        &::before {
          background-color: red;
        }
      }

      &:checked {
        +label {
          background-color: red;
        }
      }
    }
  }
}

.disabled {
  cursor: none;
  pointer-events: none;
}

#element-to-capture {
  h1 {
    color: #565d61;
    font-size: 16px;
  }

  p {
    color: #000;
    font-size: 12px;
    margin-top: -40px;
  }

  .card {
    border: 0;
    box-shadow: -1px 1px 8px rgba(16, 19, 20, 0.1);
  }
}

.border-bottom-grey {
  border-bottom: 1px solid $grey-color;
}

.announcement-popup {
  .search-input {
    input {
      height: 50px;
      background-color: rgba(196, 201, 204, 0.25);

      &:focus {
        box-shadow: none;
        border: 1px solid $primary-blue;
      }
    }

    .search-icon {
      position: absolute;
      top: 10px;
      left: 10px;
    }

    .close-button {
      position: absolute;
      top: 15px;
      left: 430px;
      cursor: pointer;
    }

  }

  span {
    color: #006699;
  }

  .card {
    border: 1px solid $grey-color;
    border-radius: 8px;
    background-color: rgba(196, 201, 204, 0.15);

    &.unread {
      background-color: #e5f6ff;
    }

    .announcement-description {
      max-height: 76px;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
    }
  }

  .previewImage {
    max-width: 400px;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    filter: invert(1);
    height: 25px;
  }

  .carousel-control-next {
    position: absolute;
    top: -40px !important;
    height: 33px;
    width: 33px !important;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease;
    left: 90% !important;

    img {
      height: 30px !important;
      width: 30px !important;
    }

  }

  .carousel-control-prev {
    position: absolute;
    top: -40px !important;
    height: 33px;
    width: 33px !important;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease;
    left: 86% !important;

  }

  .carousel-indicators {
    display: none;
  }
}

.reports-view {
  border-radius: 4px;
  box-shadow: -1px 1px 10px rgba(16, 19, 20, 0.1);

  h1 {
    color: #565d61;
  }

  .report-user {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;

    .user-report-options {
      border-radius: 8px;
      height: 100px;
      position: relative;

      .report-breadcrumb {
        position: absolute;
        top: 24px;
        left: 12px;
      }

      &:nth-child(1) {
        background-color: rgba(242, 208, 205, 1);
      }

      &:nth-child(2) {
        background-color: rgba(251, 233, 191, 1);
      }

      &:nth-child(3) {
        background-color: rgba(30, 142, 62, 0.25);
      }

      &:nth-child(4) {
        background-color: rgba(196, 219, 249, 1);
      }

      .dropdown {
        .dropdown-toggle {
          background-color: transparent;
          color: #000;
          border: 0;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 12px;

          &::after {
            background-image: url("../images/icons/chevron.png");
            border: 0;
            width: 10px;
            height: 10px;
            background-repeat: no-repeat;
            background-size: auto;
            background-position: center;
            transition: 0.4s ease-out;
          }

          &.show {
            &::after {
              transform: rotate(-180deg);
            }
          }

          .dropdown-menu {
            width: 100%;
          }
        }
      }

      .dropdown-menu {
        border: 0;
        padding-top: 0;
        padding-bottom: 40px;
        border-radius: 4px;

        &:first-of-type {
          width: 100%;
          box-shadow: -1px 1px 4px rgba(16, 19, 20, 0.1);
        }

        [aria-labelledby="dropdown-basic"] {
          transform: translate3d(192px, 33.8px, 0px) !important;

          a {
            font-size: 12px;
          }

          &:nth-last-child(2) {
            transform: translate3d(268px, 33.8px, 0px) !important;
          }

          &:last-child {
            width: fit-content;
            transform: translate3d(160px, 33.8px, 0px) !important;
          }

        }

        .dropdown-toggle {
          border-radius: 0;
          border-bottom: 1px solid #565d61;
        }

      }

      .report-index-value {
        padding: 10px;
        font-size: 32px;
        color: #565d61;
      }
    }
  }

  .user-data {
    border-radius: 8px;
    box-shadow: -1px 1px 8px rgba(16, 19, 20, 0.1);
    padding: 10px 10px 10px 10px;
  }
}


.custom-legend .chartjs-legend ul li span {
  display: block;
  width: 100%;
  /* Make labels occupy the full width */
  white-space: normal;
  /* Allow wrapping of text */
}

.grayscale-img {
  filter: grayscale(1);
  opacity: 0.7;

  &:hover {
    filter: unset;
    opacity: 1;
  }
}

.filter-options {
  border: 2px solid $grey-color;
  border-radius: 8px;

  .border-bottom-grey {
    border-bottom: 2px solid $grey-color;
  }

  .css-13cymwt-control,
  .css-t3ipsp-control {
    font-size: 12px;
    border-color: $grey-color;

    // border-radius: 8px;
    .css-1u9des2-indicatorSeparator {
      display: none;
    }

    .css-1jqq78o-placeholder {
      font-size: 14px;
    }
  }
}

.unread-inbox {
  font-weight: 900;
}

.modal-body::-webkit-scrollbar {
  width: 0;
}

.close-button-carousal {
  position: absolute;
  top: 40px;
  left: 1080px;
  cursor: pointer;
  z-index: 10;
}

.uploaded-files-container {
  display: grid;
  grid-template-columns: auto;
  justify-content: start;
  align-items: start;
  gap: 5px;
}

.uploaded-file {
  display: flex;
  align-items: center;
  background-color: $promilo-blue;
  border-radius: 20px;
  padding: 8px 12px;
  margin-right: 10px;
  font-size: 14px;
  color: #ffffff;
  border: 1px solid #ccc;
  gap: 5px;
  overflow-x: inherit;
  height: "20px";
  width: "20px";
  justify-content: space-between
}

.delete-button {
  margin-left: 5px;
  padding: 0;
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 50%;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  font-size: 14px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.legend-item-custom {
  position: relative;
  cursor: pointer;
}

.legend-comment {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  display: none;
  font-size: small;
  z-index: 99;
  font-weight: 300 !important;
}

.legend-item-custom:hover .legend-comment {
  display: block;
}

.legend-label {
  font-size: 12px;
}

.close-button-previeww {
  background: none;
  border: none;
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin-top: 7px;
  margin-right: 10px;
}

.preview-image-main {
  width: 620px;
  height: 330px;
  border-radius: 10px;
  float: left;
  margin: 13px;
  margin-left: 0px;
}

.subject-underline-text {
  border-bottom: 1px solid #000;
  padding-bottom: 3px;
}

.service-performance-total-records {
  font-weight: 500;
  font-size: 16px;
  color: $promilo-blue;
}

.service-performance-selected-records {
  font-weight: 400;
  font-size: 14px;
}

.myprofilepic {
  height: 120px;
  width: 120px;
  border: 1px solid rgb(180, 180, 180);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.myprofileheading {
  display: flex;
}

.profile-body-container {
  border: 1px solid rgb(180, 180, 180);
  border-radius: 10px;
  padding: 20px;
}

.open-document-modal {
  .modal-header {
    background-color: rgba($primary-color, 0.15);
    padding: 8px 12px;
    border-bottom: 0;
    height: 51px;

    .modal-title {
      font-size: 16px;
      font-weight: 500;
      color: $black;
    }

    .btn-close {
      background-image: url(../images/icons/modal-close.png);
      opacity: 1;
      background-size: 24px;
      margin-right: 10px;
    }
  }
}

.document-card {
  height: 66px;
  width: 300px;
  background-color: rgba(196, 201, 204, 0.25);
  display: flex;
  align-items: center;

  border-radius: 10px;

  .document-imagebox {
    height: 42px;
    width: 42px;
    background-color: white;
    border-radius: 5px;
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .document-name {
    display: flex;
    flex-direction: column;
  }
}

.search-icon-inactive {
  position: absolute;
  right: 12px;
  top: 12px;
}

.search-icon-active {
  position: absolute;
  right: 12px;
  top: 12px;
  border-radius: 50%;
  color: #000;
  transition: ease-in-out 0.5s;
}

.picture-upload-card {
  height: 62px;
  width: 185px;
  background-color: rgba(217, 242, 255, 1);
  display: flex;
  align-items: center;

  border-radius: 5px;

  .document-imagebox {
    height: 40px;
    width: 40px;
    background-color: rgba(0, 102, 153, 1);
    border-radius: 5px;
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .document-name {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.bookmark-modal {
  .modal-content {
    // bottom: 0;
    width: 350px;
    background-color: #fbfbfe;
    border-radius: 10px;
    // height: 100%;
    box-shadow: 0px 0px 8px #0000001f;
    border: 1px solid #0000001f;
    min-height: 50px;
    padding: 15px;
    margin: 0 auto;

    .btn-close {
      width: 20px;
      height: 20px;
      right: -2px;
      top: -12px;
      border-radius: 20px;
      box-shadow: none;
      background-color: white;
      padding: 0;
      background-image: url(../../assets/images/closeIconX.png);
      background-size: contain;
      transform: translate(0, 0);
      margin-bottom: 5px;

      &:hover {
        transform: translate(0, 0);
      }
    }

    .modal-header {
      background-color: white;
      padding: 0;
      position: relative;
      height: 20px;
    }

    .modal-title {
      font-size: 18px;
      font-weight: 600;
      color: black;
    }

    .bookmark-icon {
      width: 28px;
    }

    .modal-body {
      padding: 20px 0 0 0;

      .bookmark-list {
        li {
          list-style: none;

          input[type="checkbox"] {
            display: none;

            +label {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-weight: 600;
              border-bottom: 1px solid #adb5bd;
              padding: 12px 0;

              .list-icon {
                width: 26px;
                margin-right: 8px;
              }

              .tick-icon {
                display: none;
              }
            }

            &:checked {
              +label {
                .tick-icon {
                  width: 18px;
                  margin-right: 12px;
                  display: block;
                }
              }
            }
          }

          label {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 600;
            border-bottom: 1px solid #adb5bd;
            padding: 12px 0;

            .list-icon {
              width: 26px;
              margin-right: 8px;
            }

            .tick-icon {
              display: none;
            }
          }
        }
      }
    }
  }

  &.open-post-modal {
    .modal-content {
      width: 100%;
      background-color: #fbfbfe;
      border-radius: 10px;
      box-shadow: 0px 0px 8px #0000001f;
      border: 1px solid #0000001f;
      min-height: 100px;
      padding: 15px;
      min-height: 512px;

      .modal-body {
        padding: 0 !important;
      }
    }
  }
}

.enter-url-input {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #adb5bd;
  width: 100%;
  font-size: 16px;
  color: black;
}

.search-modal {
  .modal-dialog-centered {
    max-width: 767px;
  }

  .modal-content {
    width: 100%;

    .modal-body {
      padding-top: 0 !important;

      .arrow-icon {
        width: 12px;
        transform: rotate(-90deg);
      }

      .search-color-box {
        background: linear-gradient(180deg, #006699 0%, lightblue 100%);
        border-radius: 10px;
        height: 32px;
        width: 32px;
        display: inline-block;
      }
    }
  }

  .modal-search {
    width: 100%;
    background: #fbfbfe;
    border: 1px solid #8f9bb3;
    border-radius: 10px;
    height: 40px;
  }

  .divider {
    border-bottom: 1px solid #adb5bd;
    margin: 10px 0;
  }
}

.webcam-frame {
  border: 1px solid #f5f5f5;
  border-radius: 12px;
  background-color: black;
}

.camera-click {
  border: 0;
  bottom: -56px;
  right: 0;
  left: 0;
  margin: 0 auto;
  max-width: 90px;
  border-radius: 70px;

  .outer-div {
    width: 82px;
    height: 82px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 90px;
    border: 3px solid #d9d9d9;
    padding: 5px;
    background-color: #006699;

    img {
      width: 60px;
      height: 60px;
      border-radius: 80px;
    }
  }
}

.edit-icon-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
}

.myprofilepic:hover {
  cursor: pointer;
  background-color: rgb(209, 211, 211);

  .edit-icon-container {
    display: block;
  }
}

.edit-icon {
  width: 30px;
  height: 30px;
}


.controls {
  position: absolute;
  bottom: 20px;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 40px;
  display: flex;
  align-items: center;
  background-color: rgb(201, 199, 199) !important;
}

.slider {
  padding: 22px 0px;
}

.zoom-range {
  background: linear-gradient(to right, rgba(0, 102, 153, 1) 0%, rgba(0, 102, 153, 1) 50%, #fff 50%, #fff 100%);
  border: solid 1px rgba(0, 102, 153, 1);
  border-radius: 8px;
  height: 7px;
  width: 50%;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
  margin-left: 25%;
}

.zoom-range::-webkit-slider-thumb {
  // background: rgba(0, 102, 153, 1);
  width: 15px;
  -webkit-appearance: none;
  height: 15px;
  background: rgba(0, 102, 153, 1);
  border-radius: 10px;

}

.image-crop-container {
  position: absolute;
  height: 337px;
  width: 900px;
  margin-left: 37px;
  border-radius: 5px;
}

.bg-transparent {
  background-color: transparent !important;
}

.crop-icon-box {
  height: 64px;
  width: 64px;
  display: flex;
  align-items: center;
  border: 2px solid rgba(217, 217, 217, 1);
  border-radius: 10px;
  justify-content: center;
  margin-left: 37px;
}

.crop-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.crop-size-info {
  color: grey;
  font-size: large;
}

.crop-close-mark {
  display: flex;
  justify-content: center;
  align-items: center !important;
  height: 100%;
  width: 48px;
  margin-right: 20px;
  margin-top: 20px;
}

.icon-hover:hover {
  fill: blue;
  color: #3cbdff;
  filter: drop-shadow(0px 0px 0px rgb(0, 30, 255));
}

.notification-count {
  position: absolute;
  margin-left: 12px;
  background-color: red;
  border-radius: 50%;
  padding: 2px;
  color: white;
  font-size: 12px;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.notification-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;


  h5 {
    font-size: 18px;
    color: $promilo-blue;
  }

  div {
    font-size: 14px;
    color: $grey-font-color;
  }
}

.notification-content-container {

  .notification-date-text {
    font-size: 15px;
    font-weight: 500;
    color: $grey-font-color;
    padding: 15px 15px 15px 15px;
    background-color: #e8eaeb;

  }
}

.notification-item {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 17px 15px 17px 10px;
  border-bottom: solid 1px $grey-color;
  cursor: pointer;
}

.notification-item-unread {
  background-color: #d1edfa;
}

.notification-item-read {
  background-color: white;
}

.notification-item img {
  margin-right: 10px;
}

.notification-item h6 {
  margin: 0 10px 0 0;
  font-size: 1rem;
}

.notification-item p {
  margin: 0 10px 0 0;
  font-size: 0.875rem;
}

.unreadDot {
  width: 10px;
  border-radius: 50%;
  background-color: $promilo-blue;
  padding: 5px;
  margin: 11px 0px 10px 40px;

}

@media only screen and (max-width: 660px) {
  .main-section {
    width: 100%;
  }
}

.CursorPointer{
  cursor: pointer;
}